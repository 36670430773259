import { useTheme } from '@emotion/react'
import { Bolt } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { getDatabase, onValue, ref } from 'firebase/database'
import React from 'react'
import { useSelector } from 'react-redux'
import { isAdmin } from 'src/Utils/Users'
import { PageHeader } from 'src/components/Header/Page'
import { app } from 'src/firebase'
import { useFlags } from 'src/providers/Flags'
import { userSelector } from 'src/redux/features/user/adapters'
import './style.scss'

export function Dashboard () {
  const theme = useTheme()
  const user = useSelector(userSelector)
  const {
    AC_SENSOR_ENABLE,
    THRESHOLD: { AC }
  } = useFlags()

  const [logo, setLogo] = React.useState()
  const [stores, setStores] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const fetchSensorsData = React.useCallback(
    async () => {
      const db = getDatabase(app)
      const promises = user?.customers?.[0]?.stores.map(async store => {
        const storeRef = ref(db, `sensors/${store?.id}`)
        return new Promise((resolve, reject) => {
          onValue(storeRef, snapshot => {
            const data = snapshot.val()
            if (data) {
              data.store = store.name
              data.id = store.id
              data.genStatus = data?.r || data?.s || data?.t
              resolve(data)
            } else {
              resolve(null)
            }
          }, reject)
        })
      })
      if (promises && promises.length > 0) {
        setLoading(true)
        const storesData = await Promise.all(promises)
          .catch(error => {
            console.error('Error fetching data:', error) // More descriptive error message
          })
        setStores(storesData.filter(Boolean))
        setLoading(false)
      }
    },
    [user?.customers]
  )

  React.useEffect(() => {
    const logoPath = {
      bibe: require('@images/bibe_logo.svg'),
      paguemenos: require('@images/paguemenos_logo.png'),
      boa: require('@images/boa_logo.png')
    }[theme.tenant]
    setLogo(prev => logoPath && logoPath.default ? logoPath?.default : logoPath || prev)
  }, [theme.tenant])

  React.useEffect(() => {
    fetchSensorsData()
  }, [fetchSensorsData])

  return (
    <>
      {!isAdmin() && logo && (
        <Container className='dashboard-header-logo'>
          <img src={logo} alt='logo' />
        </Container>
      )}
      <PageHeader title='Dashboard' />
      <Container component='main' className='dashboard-container' maxWidth='xl'>
        {loading
          ? (
            <Box component={Paper} minWidth={700} className='loader'>
              <CircularProgress />
            </Box>
            )
          : (
              stores.length < 1
                ? (
                  <Box component={Paper} minWidth={700} p={2} className='no-stores'>
                    <Typography variant='h6' color='text.primary'>
                      Nenhuma loja encontrada
                    </Typography>
                  </Box>
                  )
                : (
                  <TableContainer component={Paper} className='stores-list'>
                    <Table sx={{ minWidth: 700 }} aria-label='customized table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Loja</TableCell>
                          {AC_SENSOR_ENABLE && <TableCell align='center'>Sistema de pressurização</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.values(stores).map((store, index) => (
                          <TableRow key={`${store}${index}`}>
                            <TableCell>
                              {store.store}
                            </TableCell>
                            {AC_SENSOR_ENABLE && (
                              <TableCell>
                                <Box className='pump-status'>
                                  <div className='power'>
                                    <Typography variant='body1' color='text.primary'>Rede</Typography>
                                    <Tooltip title={store.power}>
                                      <div className={`sensor-status ${store.power > AC ? 'green' : 'red'}`}>
                                        <Bolt />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className='main'>
                                    <Typography variant='body1' color='text.primary'>Principal</Typography>
                                    <Tooltip title={store.main}>
                                      <div className={`sensor-status ${store.main > AC ? 'green' : 'red'}`}>
                                        <Bolt />
                                      </div>
                                    </Tooltip>
                                  </div>
                                  <div className='jockey'>
                                    <Typography variant='body1' color='text.primary'>Jockey</Typography>
                                    <Tooltip title={store.jockey}>
                                      <div className={`sensor-status ${store.jockey > AC ? 'green' : 'red'}`}>
                                        <Bolt />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </Box>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  )
            )}
      </Container>
    </>
  )
}
