import { Check, Delete, Edit, PersonAdd, Remove, Search } from '@mui/icons-material'
import { Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { HttpClient } from 'src/Utils/HttpClient'

export const UsersContent = React.memo(() => {
  const [users, setUsers] = React.useState([])
  const formik = useFormik({
    initialValues: {
      name: '',
      active: false
    },
    onSubmit: values => setUsers(prev => [...prev, { ...values, updatedAt: new Date(), createdAt: new Date() }])
  })
  const fetchUsers = React.useCallback(
    async () => {
      const response = await HttpClient.get('/users')
      setUsers(response.data)
    },
    []
  )

  const handleChange = React.useCallback(
    function (event) {
      const { name, value, checked } = event.target
      if (name === 'active') {
        return formik.setFieldValue(name, checked)
      }
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  React.useEffect(
    () => {
      fetchUsers()
    },
    [fetchUsers]
  )
  return (
    <>
      <TextField
        label='Pesquisar'
        variant='standard'
        fullWidth
        InputProps={{
          endAdornment: <Search />
        }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell>Data de Criação</TableCell>
              <TableCell>Data de Atualização</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length < 1
              ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    Nenhum usuário encontrado
                  </TableCell>
                </TableRow>
                )
              : (
                  users.map(user => (
                    <TableRow key={user.id}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.active ? <Check /> : <Remove />}</TableCell>
                      <TableCell>{new Date(user.createdAt).toLocaleDateString('pt-BR')}</TableCell>
                      <TableCell>{new Date(user.updatedAt).toLocaleDateString('pt-BR')}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>
                        <IconButton onClick={() => { console.log(user) }}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => { alert(user.id) }}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  label='Nome completo'
                  variant='standard'
                  value={formik.values.name}
                  onChange={handleChange}
                  name='name'
                  id='name'
                />
              </TableCell>
              <TableCell colSpan={3}>
                <Checkbox
                  checked={formik.values.active}
                  onChange={handleChange}
                  name='active'
                  id='active'
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <IconButton onClick={formik.handleSubmit}>
                  <PersonAdd />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
})
