import { Tab, Tabs } from '@mui/material'
import React from 'react'
import { FeaturesContent } from 'src/components/Features'
import { PageHeader } from 'src/components/Header/Page'
import { PermissionsContent } from 'src/components/Permissions'
import { RolesContent } from 'src/components/Roles'
import { UsersContent } from 'src/components/UserContent/UserContent'

export function Users () {
  const [tabValue, setTabValue] = React.useState(0)
  // const [openPermissionsModal, setOpenPermissionsModal] = React.useState(false)

  /*
  const openModal = React.useCallback(
    () => {
      setOpenPermissionsModal(true)
    },
    []
  )
  const closeModal = React.useCallback(
    () => {
      setOpenPermissionsModal(false)
    },
    []
  )
  */
  const handleChange = React.useCallback(
    (event, newValue) => {
      setTabValue(newValue)
    },
    []
  )

  return (
    <>
      <PageHeader title='Usuários' />
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label='Usuários' />
        <Tab label='Papeis' />
        <Tab label='Funcionalidades' />
        <Tab label='Permissões' />
      </Tabs>
      {tabValue === 0 && (
        <UsersContent />
      )}
      {tabValue === 1 && (
        <RolesContent />
      )}
      {tabValue === 2 && (
        <FeaturesContent />
      )}
      {tabValue === 3 && (
        <PermissionsContent />
      )}
    </>
  )
}
