import { Delete, Edit } from '@mui/icons-material'
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { HttpClient } from 'src/Utils/HttpClient'
import './styles.scss'

export const FeaturesContent = React.memo(() => {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
    }
  })

  const [features, setFeatures] = React.useState([])

  const fetchFeatures = React.useCallback(
    async () => {
      const response = await HttpClient.get('/resources')
      setFeatures(response.data)
    },
    []
  )
  const deletePermission = React.useCallback(
    async id => {
      await HttpClient.delete(`/resources/${id}`)
      fetchFeatures()
    },
    [fetchFeatures]
  )

  const handleChange = React.useCallback(
    function (event) {
      const { name, value } = event.target
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const handleSubmit = React.useCallback(
    async () => {
      const values = formik.values
      const response = await HttpClient.post('/features', values)
      setFeatures(prev => [...prev, response?.data])
      formik.resetForm()
    },
    [formik]
  )

  React.useEffect(
    () => {
      fetchFeatures()
    },
    [fetchFeatures]
  )
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Funcionalidade</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {features.map(feature => (
              <TableRow key={feature.id}>
                <TableCell>{feature.name}</TableCell>
                <TableCell>{feature.description}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <IconButton onClick={() => {}}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => deletePermission(feature.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  label='Nome'
                  variant='standard'
                  value={formik.values.name}
                  onChange={handleChange}
                  name='name'
                  id='name'
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  label='Descrição'
                  variant='standard'
                  value={formik.values.description}
                  onChange={handleChange}
                  name='description'
                  id='description'
                />
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <Button variant='outlined' onClick={handleSubmit}>Criar</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
})
