import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { NotFound } from 'src/pages/404'
import { Customers } from 'src/pages/Customers'
import { Dashboard } from 'src/pages/Dashboard'
import { DashboardV2 } from 'src/pages/DashboardV2'
import SignIn from 'src/pages/SignIn'
import { Store } from 'src/pages/Store'
import { Stores } from 'src/pages/Stores'
import { Users } from 'src/pages/Users'
import { PATHS } from 'src/Utils/Router'
import { MainBase } from '../Base'
import { PrivateRoute } from './PrivateRoute'

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <MainBase />
            </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path='/v2' element={<DashboardV2 />} />
          <Route path={PATHS.CUSTOMERS} element={<Customers />} />
          <Route path={PATHS.USERS} element={<Users />} />
          <Route path={`${PATHS.STORE}/:storeID`} element={<Store />} />
          <Route path={PATHS.STORES} element={<Stores />} />
        </Route>
        <Route path={PATHS.SIGN_IN} element={<SignIn />} />
        <Route path={PATHS.NOT_FOUND} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
