import { Delete } from '@mui/icons-material'
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { HttpClient } from 'src/Utils/HttpClient'
import './styles.scss'

export const RolesContent = React.memo(() => {
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      permissions: []
    }
  })

  const [roles, setRoles] = React.useState([])
  // const [, setPermissions] = React.useState([])

  const fetchRoles = React.useCallback(
    async () => {
      const response = await HttpClient.get('/roles')
      setRoles(response.data)
    },
    []
  )

  /* const fetchPermissions = React.useCallback(
    async () => {
      const response = await HttpClient.get('/permissions')
      setPermissions(response.data)
    },
    []
  ) */

  const deletePermission = React.useCallback(
    async id => {
      await HttpClient.delete(`/roles/${id}`)
      fetchRoles()
    },
    [fetchRoles]
  )

  const handleChange = React.useCallback(
    function (event) {
      const { name, value } = event.target
      formik.setFieldValue(name, value)
    },
    [formik]
  )

  const handleSubmit = React.useCallback(
    async () => {
      const actionValues = formik.values.action
        .filter(action => action.checked)
        .map(action => action.name)
      const action = JSON.stringify(actionValues)
      const values = { ...formik.values, action }
      const response = await HttpClient.post('/roles', values)
      setRoles(prev => [...prev, response?.data])
      formik.resetForm()
    },
    [formik]
  )

  React.useEffect(
    () => {
      fetchRoles()
      // fetchPermissions()
    },
    [/* fetchPermissions,  */fetchRoles]
  )
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Funcionalidade</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell sx={{ textAlign: 'right' }}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roles.map(role => (
            <TableRow key={role.id}>
              <TableCell>{role.name}</TableCell>
              <TableCell>{role.description}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <IconButton onClick={() => deletePermission(role.id)}>
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <TextField
                fullWidth
                label='Nome'
                variant='standard'
                value={formik.values.name}
                onChange={handleChange}
                name='name'
                id='name'
              />
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                label='Descrição'
                variant='standard'
                value={formik.values.description}
                onChange={handleChange}
                name='description'
                id='description'
              />
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
              <Button variant='outlined' onClick={handleSubmit}>Criar</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
})
